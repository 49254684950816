<template>
  <div class="groupbuy-list">
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'MainGroupbuyingsList',
              }"
              >總公司團購管理</b-breadcrumb-item
            >
            <b-breadcrumb-item active>總公司團購列表</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <div class="col-6 d-flex align-items-center">
          <h4 class="mb-2 mb-xl-0 mr-2 font-weight-bold">總公司團購列表</h4>
        </div>

        <div
          class="
            col-6
            d-flex
            justify-content-end
            align-items-start align-items-xl-center
          "
        >
          <b-button
            v-if="checkPermission([consts.MAIN_STORE_GROUPBUYING_CREATE])"
            class="flex-shrink-0 mb-2 mb-xl-0 ml-2 py-2"
            variant="primary"
            @click="handleCreateByScan"
            ><i class="fa fa-plus" aria-hidden="true"></i
            >門市庫存品開團</b-button
          >
          <b-button
            v-if="checkPermission([consts.MAIN_GROUPBUYING_CREATE])"
            class="flex-shrink-0 mb-2 mb-xl-0 ml-2 py-2"
            variant="primary"
            @click="handleCreate"
            ><i class="fa fa-plus" aria-hidden="true"></i
            >總公司選品開團</b-button
          >
        </div>
      </div>

      <div class="row align-items-center mb-3">
        <div class="col-12 col-xl-6">
          <div class="align-items-center mb-2 mb-xl-0">
            <b-form-group
              v-slot="{ ariaDescribedby }"
              class="flex-shrink-0 ml-1 ml-xl-2 mb-0"
            >
              <b-form-checkbox-group
                id="checkbox-group-1"
                v-model="selectedTypes"
                :options="statusOptions"
                :aria-describedby="ariaDescribedby"
                @change="handleCheckedTypes()"
              ></b-form-checkbox-group>
            </b-form-group>
          </div>
        </div>
      </div>

      <div class="d-flex flex-wrap mb-3 justify-content-end">
        <div class="row-searchbar d-flex mb-2 mb-md-0 mr-md-2">
          <b-form-select
            class="mr-2"
            style="width: 120px; height: 45px"
            v-model="keyword_type"
            :options="SEARCH_OPTIONS"
          ></b-form-select>
          <div class="w-100">
            <b-form-input
              v-model="query.keyword"
              :placeholder="PLACEHOLDER_MAP[keyword_type]"
              @keyup.enter="getGroupbuyList"
            ></b-form-input>
          </div>
        </div>

        <DateSearchPicker
          class="mr-md-2 mr-0"
          v-model="query"
          :disabledEndAtDates.sync="disabledEndAtDates"
        />

        <b-button @click="getGroupbuyList"
          ><i class="fa fa-search"></i
        ></b-button>
      </div>
      <b-tabs v-model="currentTabIndex" @input="handleChangeTab">
        <b-tab v-for="tab in tabs" :title="tab.title" :key="tab.status">
          <b-card-text>
            <div class="row">
              <div class="col-12">
                <b-table
                  striped
                  hover
                  responsive
                  :items="groupbuys"
                  :fields="fields"
                  :busy="isFetchGroupbuyList"
                >
                  <template #table-busy>
                    <div class="text-center my-2">
                      <b-spinner class="align-middle"></b-spinner>
                    </div>
                  </template>

                  <template #cell(groupbuying_number)="data">
                    <router-link
                      :to="{
                        name: 'GroupbuyingsDetail',
                        params: {
                          groupbuyID: data.item.id,
                        },
                      }"
                      >{{ data.item.groupbuying_number }}
                    </router-link>
                  </template>

                  <template #cell(title)="data">
                    <a @click.prevent="handleViewDetail(data.item)" href="javascript:;">
                      <div>
                        {{
                          data.item.products[0] && data.item.products[0].title
                        }}
                      </div>
                      <div class="mb-1">
                        {{
                          data.item.products[0] &&
                          data.item.products[0].sale_page_code
                        }}
                      </div>
                    </a>
                  </template>

                  <template #cell(picture)="data">
                    <b-img-lazy
                      v-if="
                        data.item.products[0] &&
                        data.item.products[0].image_list[0] &&
                        data.item.products[0].image_list[0].pic_url
                      "
                      class="thumb-image"
                      :src="
                        data.item.products[0] &&
                        data.item.products[0].image_list[0].pic_url
                      "
                      alt="商品圖片"
                      width="50"
                      height="50"
                    />
                  </template>

                  <template #cell(date)="data">
                    <div class="mb-1">
                      {{
                        format(new Date(data.item.start_at), "yyyy-MM-dd HH:mm")
                      }}
                    </div>
                    <div>
                      {{
                        format(new Date(data.item.end_at), "yyyy-MM-dd HH:mm")
                      }}
                    </div>
                  </template>

                  <!-- <template #cell(arrange)="data">
                    <div class="d-flex">
                      <b-button
                        variant="inverse-info"
                        :to="{
                          name: 'MainGroupbuyingsDetail',
                          params: { groupbuyID: data.item.id },
                        }"
                        >查看</b-button
                      >
                      <b-button
                        v-if="checkPermission([consts.MAIN_GROUPBUYING_MODIFY]) && ['draft', 'waiting', 'going'].includes(tab.id)"
                        class="ml-2"
                        variant="inverse-warning"
                        :to="{
                          name: 'MainGroupbuyingsEdit',
                          params: { groupbuyID: data.item.id },
                        }"
                        >編輯</b-button
                      >
                      <b-button
                        v-if="checkPermission([consts.MAIN_GROUPBUYING_MODIFY]) && ['draft', 'waiting'].includes(tab.id)"
                        class="ml-2"
                        variant="inverse-danger"
                        @click="handleDelete(data.item)"
                        >刪除</b-button
                      >
                    </div>
                  </template> -->
                </b-table>
              </div>
            </div>
          </b-card-text>
        </b-tab>
      </b-tabs>

      <div
        class="
          d-flex
          justify-content-center
          align-items-center
          flex-column flex-md-row
        "
        style="margin-top: 80px"
      >
        <b-pagination
          class="separated mb-2 mb-md-0"
          v-model="query.page"
          :total-rows="total"
          :per-page="query.per_page"
          aria-controls="groupbuyList"
          @change="handleChangePage"
        ></b-pagination>
        <div class="ml-2">
          每頁顯示數量<b-form-select
            v-model="query.per_page"
            :options="perPageOptions"
            style="width: 70px"
            class="ml-2"
            @change="handleChangePage"
          ></b-form-select>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { format } from "date-fns";
import { BTable } from "bootstrap-vue";
import { mapState } from "vuex";
import mainGroupbuyApi from "@/apis/main-groupbuyings";
import checkPermission from "@/mixins/checkPermission";
import DateSearchPicker from "@/components/Page/Dashboard/DateSearchPicker.vue";
import _ from "lodash";

import { SEARCH_OPTIONS, PLACEHOLDER_MAP } from "@/utils/search";

export default {
  mixins: [checkPermission],
  components: { BTable, DateSearchPicker },
  data() {
    return {
      SEARCH_OPTIONS,
      PLACEHOLDER_MAP,
      isFetchGroupbuyList: false,
      query: {
        keyword: "",
        start_at: null,
        end_at: null,
        page: 1,
        per_page: 10,
      },
      keyword_type: "product", // product or group
      groupbuys: [],
      sortBy: "start_at",
      sortDesc: false,
      fields: [
        {
          key: "title",
          label: "商品名稱",
        },
        {
          key: "picture",
          label: "商品圖片",
        },
        {
          key: "products[0].computed_unit_price",
          label: "單價",
          thClass: "text-center",
        },
        {
          key: "branches_quantity",
          label: "開團數量",
          class: "text-center",
        },
        {
          key: "date",
          label: "開團/結團時間",
        },
        // {
        //   key: "arrange",
        //   label: "管理",
        // },
      ],
      perPageOptions: [10, 50, 100],
      currentPage: 1,
      total: 0,
      currentTabIndex: 0,
      showGroupbuyListType: true,
      statusOptions: [
        { text: "門市庫存品", value: '4' },
        { text: "總公司選品", value: '2' },
      ],
      selectedTypes: ['4', '2'],
    };
  },

  created() {
    const { keyword_type, keyword, tab } = this.$route.query || {};
    this.currentTabIndex = this.tabs.find((t) => t.id === tab)?.status || 0;
    if (keyword || keyword_type) {
      this.keyword = keyword;
      this.keyword_type = keyword_type;
    }
    this.getGroupbuyList();
  },

  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    disabledStartAtDates() {
      if (this.query.end_at) {
        let startAt = new Date(
          this.query.end_at.getFullYear(),
          this.query.end_at.getMonth(),
          this.query.end_at.getDate()
        );
        return { from: startAt };
      }
      return {};
    },
    disabledEndAtDates() {
      if (this.query.start_at) {
        let endAt = new Date(
          this.query.start_at.getFullYear(),
          this.query.start_at.getMonth(),
          this.query.start_at.getDate() + 1
        );
        return { to: endAt };
      }
      return {};
    },
    currentTabStatus() {
      return this.tabs[this.currentTabIndex].status;
    },
    tabs() {
      if (this.checkPermission([this.consts.GROUPBUYING_STATUS_MODIFY])) {
        return [
          {
            id: "draft",
            title: "草稿",
            status: 8,
          },
          {
            id: "waiting",
            title: "準備中",
            status: 0,
          },
          {
            id: "going",
            title: "開團中",
            status: 1,
          },
          {
            id: "confirmed",
            title: "已結單",
            status: 2,
          },
          {
            id: "finished",
            title: "待取貨",
            status: 3,
          },
          {
            id: "pending",
            title: "暫停收單",
            status: 4,
          },
          {
            id: "cancelled",
            title: "未成團",
            status: 5,
          },
          {
            id: "archived",
            title: "已完成",
            status: 6,
          },
          {
            id: "deleted",
            title: "已刪除",
            status: 9,
          },
        ];
      }

      return [
        {
          id: "waiting",
          title: "準備中",
          status: 0,
        },
        {
          id: "going",
          title: "開團中",
          status: 1,
        },
        {
          id: "finished",
          title: "待取貨",
          status: 3,
        },
        {
          id: "archived",
          title: "已完成",
          status: 6,
        },
      ];
    },
  },

  methods: {
    format,
    async getGroupbuyList() {
      if (this.query.start_at > this.query.end_at) {
        this.$swal.fire({
          type: "warning",
          text: "查詢結束時間必須大於起始時間",
        });
        return;
      }

      const { keyword, start_at, end_at, page, per_page } = this.query;
      let params = {
        keyword,
        page,
        per_page,
        ...(start_at && { start_at: format(start_at, "yyyy-MM-dd") }),
        ...(end_at && { end_at: format(end_at, "yyyy-MM-dd") }),
        status: this.currentTabStatus,
      };

      if (this.selectedTypes.length !== 0 && this.showGroupbuyListType) {
        const types = _
          .chain(this.selectedTypes)
          .map(function (type) {
            return type.split(',');
          })
          .flattenDeep()
          .value();

        params.types = types;
      }

      try {
        this.isFetchGroupbuyList = true;
        const { data } = await mainGroupbuyApi.getMainGroupbuyList(params);
        this.total = data.meta.total;
        this.query.per_page = data.meta.per_page;
        this.groupbuys = data.data;
      } catch (error) {
        console.log("error", error);
      }
      this.isFetchGroupbuyList = false;
    },

    handleCreateByScan() {
      this.$router.push({ name: "MainGroupbuyingScanProduct" });
    },

    handleCreate() {
      this.$router.push({ name: "MainGroupbuyingsCreate" });
    },

    handleCheckedTypes() {
      this.currentPage = 1;
      this.getGroupbuyList();
    },

    handleChangeTab(tab) {
      this.getGroupbuyList();
      this.currentPage = 1;
      const status = this.tabs.find((t) => t.status === tab)?.id;
      this.$router.push({ query: { tab: status } });
    },

    handleChangePage() {
      this.$nextTick(() => {
        this.getGroupbuyList();
      });
    },

    handleSort(sortBy) {
      if (this.sortBy === sortBy) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortBy = sortBy;
      }
      this.currentPage = 1;
      this.getGroupbuyList();
    },

    async handleViewDetail(item) {
      if (item.type === 2) {
        await this.$router.push({
          name: 'MainGroupbuyingsDetail',
          params: {
            groupbuyID: item.id,
          },
        });
        return
      }

      if (item.type === 4) {
        await this.$router.push({
          name: 'MainStoreGroupbuyingDetail',
          params: {
            groupbuyID: item.id,
          },
        });
        return;
      }

      this.$swal("錯誤", "團購類型非總公司選品或總公司門市庫存品開團", "error");
    },

    // async handleDelete(data) {
    //   this.$swal
    //     .fire({
    //       type: "warning",
    //       title: "你確定要刪除嗎？",
    //       html: `
    //         <div class="d-block">
    //           <div class="my-3">
    //             <div> <strong>團購編號：${data.groupbuying_number}</strong></div>
    //             <div>商品名稱：${data.products?.[0].title}</div>
    //           </div>
    //           <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
    //         </div>`,
    //       confirmButtonText: "確定刪除",
    //       cancelButtonText: "不刪除",
    //       showCancelButton: true,
    //       confirmButtonColor: "#d33",
    //       cancelButtonColor: "#3085d6",
    //       reverseButtons: true,
    //     })
    //     .then(async (result) => {
    //       if (result.value) {
    //         try {
    //           const response = await mainGroupbuyApi.deleteGroupbuying(data.id);
    //           if (response.status === 200) {
    //             await this.$swal.fire({
    //               title: "成功",
    //               type: "success",
    //               text: "刪除成功",
    //             });
    //             await this.getGroupbuyList();
    //           }
    //         } catch (error) {
    //           this.$swal("失敗", error.response.data.message, "error");
    //         }
    //       }
    //     });
    // },
  },
};
</script>

<style lang="scss">
.popover {
  max-width: 600px;
}

.groupbuy-list {
  .nav-tabs .nav-link.active,
  .table thead {
    background: rgba(255, 98, 88, 0.2);
  }

  .row-searchbar {
    width: 35%;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}
</style>
