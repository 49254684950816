export const SEARCH_OPTIONS = [
    {
        text: "商品",
        value: "product",
    },
    {
        text: "群組",
        value: "group",
    },
    {
        text: "員編",
        value: "tracking_code",
    }
]

export const PLACEHOLDER_MAP = {
    product: "搜尋商品名稱/編號",
    group: "搜尋群組名稱",
    tracking_code: "搜尋員編",
}